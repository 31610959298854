exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-index-jsx": () => import("./../../../src/templates/about/index.jsx" /* webpackChunkName: "component---src-templates-about-index-jsx" */),
  "component---src-templates-contact-index-jsx": () => import("./../../../src/templates/contact/index.jsx" /* webpackChunkName: "component---src-templates-contact-index-jsx" */),
  "component---src-templates-events-landing-index-jsx": () => import("./../../../src/templates/eventsLanding/index.jsx" /* webpackChunkName: "component---src-templates-events-landing-index-jsx" */),
  "component---src-templates-news-index-jsx": () => import("./../../../src/templates/news/index.jsx" /* webpackChunkName: "component---src-templates-news-index-jsx" */),
  "component---src-templates-news-landing-index-jsx": () => import("./../../../src/templates/newsLanding/index.jsx" /* webpackChunkName: "component---src-templates-news-landing-index-jsx" */),
  "component---src-templates-projects-index-jsx": () => import("./../../../src/templates/projects/index.jsx" /* webpackChunkName: "component---src-templates-projects-index-jsx" */),
  "component---src-templates-projects-landing-index-jsx": () => import("./../../../src/templates/projectsLanding/index.jsx" /* webpackChunkName: "component---src-templates-projects-landing-index-jsx" */)
}

